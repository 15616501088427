import { useCallback, useEffect, useRef, useState } from "react";

export function useStateCallback<T>(initialState: T): [T, (state: T, callabck?: (state: T) => void) => void] {
    const [state, setState] = useState(initialState);
    const callabckRef = useRef<((state: T) => void) | undefined>(undefined);

    const setStateCallback = useCallback((state: T, callabck?: (state: T) => void) => {
        callabckRef.current = callabck;
        setState(state);
    }, []);

    useEffect(() => {
        if (callabckRef.current) {
            callabckRef.current(state);
            callabckRef.current = undefined;
        }
    }, [state]);

    return [state, setStateCallback];
}
