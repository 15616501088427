import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryUrl } from "../lib/helpers/api";

interface GetExternalMetaRequest {
    url: string;
}

interface GetExternalMetaResponse {
    title: string;
    description: string;
    image: string;
}

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_META_URL,
});

export const metaApiSlice = createApi({
    reducerPath: "meta",
    baseQuery,
    endpoints: (builder) => ({
        getExternalMeta: builder.query<GetExternalMetaResponse, GetExternalMetaRequest>({
            query: (args) => {
                return getQueryUrl(["parse/external"], args);
            },
        }),
    }),
});

export const { useGetExternalMetaQuery } = metaApiSlice;
