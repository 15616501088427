import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from "react";
import * as signalR from "@microsoft/signalr";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getClientCommunicateMessageTypeByKey } from "../shared/model/clientCommunicateMessageType";
import { apiSlice } from "../shared/api/apiSlice";
import { useSyncOptimisticBrinxBux } from "../features/brinxBux/lib/hooks/useSyncOptimisticBrinxBux";
import { App } from "@capacitor/app";
import webSocketsLockResolver from "../shared/lib/helpers/webSocketsLockResolver";
import { selectAccessToken } from "../shared/lib/authSlice";

const AGENT_URL = `${process.env.REACT_APP_BACK_URL}/hubs/agent`;
const USER_URL = `${process.env.REACT_APP_BACK_URL}/hubs/user`;

interface ISignalRContext {
    agentConnection: signalR.HubConnection | undefined;
    userConnection: signalR.HubConnection | undefined;
    onAddGeneralMessageReceived: (onMessageReceived: (message: string) => void) => void;
}

export const SignalRContext = createContext<ISignalRContext>({
    agentConnection: undefined,
    userConnection: undefined,
    onAddGeneralMessageReceived: (onMessageReceived: (message: string) => void) =>
        console.warn("SignalRContext: onAddGeneralMessageReceived is not provided"),
});

export const SignalRContextProvider = (props: PropsWithChildren) => {
    let agentLockResolver = webSocketsLockResolver("agent");
    let userLockResolver = webSocketsLockResolver("user");

    const dispatch = useAppDispatch();

    const [agentConnection, setAgentConnection] = useState<signalR.HubConnection>();
    const [userConnection, setUserConnection] = useState<signalR.HubConnection>();

    const createAgentConnection = useCallback(() => {
        const connection = new HubConnectionBuilder()
            .withUrl(AGENT_URL, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        setAgentConnection(connection);
    }, []);

    useEffect(() => {
        if (agentConnection) {
            return;
        }

        createAgentConnection();
    }, [agentConnection, createAgentConnection]);

    const startAgentConnection = useCallback(() => {
        if (agentConnection && agentConnection.state === HubConnectionState.Disconnected) {
            agentConnection
                .start()
                .then(() => {
                    console.log("agent connected");
                })
                .catch((err) => console.error(err.toString()));
        }

        agentConnection?.on("GeneralMessage", (message: string) => {
            if (message === getClientCommunicateMessageTypeByKey("auctionBidUpdated").key) {
                dispatch(apiSlice.util.invalidateTags(["Auctions"]));
                return;
            }
            if (message === getClientCommunicateMessageTypeByKey("brinxBuxLevelUpdated").key) {
                dispatch(apiSlice.util.invalidateTags(["RedeemCollections"]));
                return;
            }
            if (message === getClientCommunicateMessageTypeByKey("redeemProductUpdated").key) {
                dispatch(apiSlice.util.invalidateTags(["Redeem-Products"]));
                return;
            }
            if (message === getClientCommunicateMessageTypeByKey("notificationCreated").key) {
                dispatch(apiSlice.util.invalidateTags(["Notifications"]));
                return;
            }
        });

        agentConnection?.on("SiteDownTemporarilyUpdatedMessage", () => {
            dispatch(
                apiSlice.util.invalidateTags([
                    {
                        type: "AppSettings" as const,
                        id: "isSiteDownTemporarily",
                    },
                ]),
            );
        });

        agentConnection?.on("BrinxBuxSettingsUpdated", () => {
            dispatch(apiSlice.util.invalidateTags(["ShopShippingInfo"]));
        });
    }, [agentConnection, dispatch]);

    useEffect(() => {
        startAgentConnection();
    }, [startAgentConnection]);

    const agentReconnect = useCallback(() => {
        if (!agentConnection) {
            return;
        }

        agentConnection.stop().finally(() => startAgentConnection());
    }, [agentConnection, startAgentConnection]);

    const onAddGeneralMessageReceivedHandle = useCallback(
        (onMessageReceived: (message: string) => void) => {
            agentConnection?.on("GeneralMessage", (message: string) => {
                onMessageReceived(message);
            });
        },
        [agentConnection],
    );

    const accessToken = useAppSelector(selectAccessToken);
    const [lastToken, setLastToken] = useState(accessToken);
    const createUserConnection = useCallback((token: string) => {
        const connection = new HubConnectionBuilder()
            .withUrl(USER_URL, {
                accessTokenFactory: () => token,
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        setUserConnection(connection);
    }, []);

    useEffect(() => {
        const token = accessToken;
        if (!token) {
            userLockResolver?.(null);
            userConnection?.stop();
            setUserConnection(undefined);
            return;
        }

        if (userConnection && lastToken === token) {
            return;
        }

        setLastToken(token);
        createUserConnection(token);
    }, [createUserConnection, accessToken, lastToken, setLastToken, userConnection, userLockResolver]);

    const { syncOptimisticBrinxBux: syncBB } = useSyncOptimisticBrinxBux();
    const startUserConnection = useCallback(() => {
        if (userConnection && userConnection.state === HubConnectionState.Disconnected) {
            userConnection
                .start()
                .then(() => {
                    console.log("user connected");
                })
                .catch((err) => console.error(err.toString()));
        }

        userConnection?.on("BrinxBuxUpdated", (message: { amount: number; brinxBuxTypeId: number }) => {
            syncBB();
        });
        userConnection?.on("SendMessage", (message: string) => {
            if (message === getClientCommunicateMessageTypeByKey("shopCartUpdated").key) {
                dispatch(apiSlice.util.invalidateTags(["CartProducts"]));
                return;
            }
        });
    }, [dispatch, syncBB, userConnection]);

    useEffect(() => {
        startUserConnection();
    }, [startUserConnection]);

    const userReconnect = useCallback(() => {
        if (!userConnection) {
            return;
        }

        userConnection.stop().finally(() => startUserConnection());
    }, [userConnection, startUserConnection]);

    const reconnectHubs = useCallback(() => {
        userReconnect();
        agentReconnect();
    }, [agentReconnect, userReconnect]);

    useEffect(() => {
        const addListener = async () => {
            const resumeListener = await App.addListener("resume", reconnectHubs);

            return resumeListener;
        };

        const addListenerResult = addListener();

        return () => {
            addListenerResult.then((results) => results.remove());
        };
    }, [reconnectHubs]);

    const providerProps = useMemo<ISignalRContext>(
        () => ({
            agentConnection,
            userConnection,
            onAddGeneralMessageReceived: onAddGeneralMessageReceivedHandle,
        }),
        [agentConnection, onAddGeneralMessageReceivedHandle, userConnection],
    );

    return <SignalRContext.Provider value={providerProps}>{props.children}</SignalRContext.Provider>;
};
