import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAuthState {
    accessToken: string | undefined;
    refreshToken: string | undefined;
}

let initialState: IAuthState = {
    accessToken: localStorage.getItem("AccessToken") ?? undefined,
    refreshToken: localStorage.getItem("RefreshToken") ?? undefined,
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        tokenReceived(state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) {
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;

            localStorage.setItem("AccessToken", action.payload.accessToken);
            localStorage.setItem("RefreshToken", action.payload.refreshToken);
        },
        loggedOut(state) {
            state.accessToken = undefined;
            state.refreshToken = undefined;
            localStorage.removeItem("AccessToken");
            localStorage.removeItem("RefreshToken");
        },
    },
    extraReducers: (builder) => {
        builder.addCase(authSlice.actions.loggedOut, () => {
            localStorage.removeItem("lastSelectedProducts");
            localStorage.removeItem("lastSelectedRedeemProducts");
            localStorage.removeItem("triedAccess");
        });
    },
});

export const selectAccessToken = (state: RootState) => state.auth.accessToken;

export const authSliceReducers = authSlice.actions;
