import { useAppDispatch } from "../../../../app/hooks";
import { setBrinxBux } from "../../../../entities/profile/model/profileSlice";
import { useLazyGetBrinxBuxQuery } from "../../../../entities/brinxBux/api/brinxBuxApiSlice";
import { useCallback } from "react";

export const useSyncOptimisticBrinxBux = () => {
    const dispatch = useAppDispatch();

    const { trigger: getBrinxBux, isLoading, isSuccess, isError } = useLazyGetBrinxBuxQuery();

    const syncOptimisticBrinxBux = useCallback(() => {
        getBrinxBux().then((response) => dispatch(setBrinxBux(response.toString())));
    }, [dispatch, getBrinxBux]);

    return {
        syncOptimisticBrinxBux,
        isBrinxBuxLoading: isLoading,
        isSyncFinished: isError || isSuccess,
    };
};
