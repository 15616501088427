import clsx from "clsx";
import BrinxTVLogo from "../../assets/Common/main-brinxlogo-second.svg";
import BrinxTVIcon from "../../assets/Common/magic-button.svg";
import styles from "./UpdateScreen.module.scss";
import { Capacitor } from "@capacitor/core";
import "./UpdateScreen.scss";
import { useEffect } from "react";

const UpdateScreen = () => {
    useEffect(() => {
        if (Capacitor.getPlatform() === "ios") {
            const headerMetaTag = document.createElement("meta");
            headerMetaTag.name = "viewport";
            headerMetaTag.content = "initial-scale=1, viewport-fit=cover";
            document.head.appendChild(headerMetaTag);
            document.body.classList.add("native-ios");
            return () => {
                document.head.removeChild(headerMetaTag);
            };
        }
    }, []);
    return (
        <div className={clsx(styles.wrapper, "update-screen-wrapper")}>
            <div className={styles.row}></div>
            <div className={styles.content}>
                <div className={styles.contentTop}>
                    <div className={styles.icon}>
                        <img src={BrinxTVIcon} alt="icon" />
                    </div>
                    <div className={styles.logo}>
                        <img src={BrinxTVLogo} alt="logo" />
                    </div>
                </div>
                <div className={styles.contentMiddle}>
                    <p className={styles.smallText}>
                        THE APP IS CURRENTLY UNDER REVIEW & WILL BE AVAILABLE IN THE NEXT FEW DAYS.
                    </p>
                </div>
                <div className={styles.contentBottom}>
                    <p className={styles.bottomText30}>UNTIL THEN, ENTER</p>
                    <p className={styles.bottomText50}>THE KINGDOM OF AWESOMENESS</p>
                    <a href="https://brinx.tv/" target="blank" className={styles.bottomText50}>
                        <span>HERE!</span>
                    </a>
                </div>
            </div>
            <div className={styles.row}></div>
        </div>
    );
};

export default UpdateScreen;
