import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { Capacitor } from "@capacitor/core";

const useNativeGoogleSignIn = () => {
    const signInWithGoogle = async () => {
        if (!Capacitor.isNativePlatform()) {
            return;
        }

        await GoogleAuth.initialize();

        const signInResult = await GoogleAuth.signIn();
        return signInResult;
    };

    return signInWithGoogle;
};

export default useNativeGoogleSignIn;
