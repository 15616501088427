import { StatusBar } from "@capacitor/status-bar";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Capacitor } from "@capacitor/core";
import UpdateScreen from "../pages/updateScreen/UpdateScreen";
import { AppUpdate, AppUpdateAvailability, AppUpdateInfo } from "@capawesome/capacitor-app-update";
import { useLazyAuthCheckQuery } from "../features/auth/authApiSlice";
import { Dialog } from "@capacitor/dialog";
import { Routing } from "../pages/Routing";
import "./index.scss";
import AppUrlListener from "./AppUrlListener";
import { withContextProviders } from "./providers/withContextProviders";
import { withRouter } from "./providers/withRouter";
import { useAppSelector } from "./hooks";
import { useGetTypedAppSettings } from "../entities/appSettings/api/appSettingsApiSlice";
import { isUndefined } from "lodash";
import SiteDownTemporarily from "../pages/errors/SiteDownTemporarily/SiteDownTemporarily";
import { useViewNotificationMutation } from "../features/notifications/notificationsApiSlice";
import { Network } from "@capacitor/network";
import useResetApiStates from "../shared/lib/hooks/useResetApiStates";
import { App as CapacitorApp } from "@capacitor/app";
import InternetConnectionLost from "../pages/errors/InternetConnectionLost/InternetConnectionLost";

const AppBase = () => {
    return (
        <>
            <AppUrlListener />
            <Routing />
        </>
    );
};

const AppWithRouterAndContextProviders = withRouter(withContextProviders(<AppBase />));
interface IUpdatedVersionRouter {
    isUpgradeRequired: boolean;
    appUpdateInfo: AppUpdateInfo;
    children: ReactNode;
}
const UpdatedVersionRouter: React.FC<IUpdatedVersionRouter> = (props) => {
    const [versionCheck] = useLazyAuthCheckQuery();
    const { isUpgradeRequired, appUpdateInfo, children } = props;

    useEffect(() => {
        const updateVersion = async () => {
            if (appUpdateInfo.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
                if (isUpgradeRequired) {
                    if (Capacitor.getPlatform() === "android") {
                        await AppUpdate.getAppUpdateInfo();
                        AppUpdate.performImmediateUpdate();
                    } else {
                        Dialog.alert({
                            message: "Please update application to the latest version",
                            title: "Update required!",
                            buttonTitle: "Update",
                        }).then(() => {
                            AppUpdate.openAppStore();
                        });
                    }
                } else if (Capacitor.getPlatform() === "android") {
                    await AppUpdate.getAppUpdateInfo();
                    AppUpdate.startFlexibleUpdate();
                } else {
                    Dialog.confirm({
                        message: "Do you want to update application?",
                        title: "Update available",
                        okButtonTitle: "Update",
                    }).then((value) => {
                        if (value.value) AppUpdate.openAppStore();
                    });
                }
            }
        };

        versionCheck();
        updateVersion();
    }, [appUpdateInfo.updateAvailability, isUpgradeRequired, versionCheck]);

    return <>{isUpgradeRequired === true ? <UpdateScreen /> : children}</>;
};

interface IUpdatedVersionRouterWithDataProps {
    children: ReactNode;
}
const UpdatedVersionRouterWithData = (props: IUpdatedVersionRouterWithDataProps) => {
    const { children } = props;
    const upgradeRequired = useAppSelector((state) => state.app.upgradeRequired);

    const [appUpdateInfo, setAppUpdateInfo] = useState<AppUpdateInfo | undefined>();
    const [versionCheck] = useLazyAuthCheckQuery();

    useEffect(() => {
        if (Capacitor.getPlatform() === "android") StatusBar.setBackgroundColor({ color: "#000000" });

        AppUpdate.getAppUpdateInfo().then((value) => {
            setAppUpdateInfo(value);
        });
    }, []);

    useEffect(() => {
        versionCheck();
    }, [versionCheck]);

    if (upgradeRequired === null) return children;
    if (appUpdateInfo === undefined) return children;

    return (
        <UpdatedVersionRouter isUpgradeRequired={upgradeRequired} appUpdateInfo={appUpdateInfo}>
            {children}
        </UpdatedVersionRouter>
    );
};

interface ISiteDownTemporarilyProps {
    children: ReactNode;
}
const SiteDownTemporarilyData = (props: ISiteDownTemporarilyProps) => {
    const { children } = props;
    const [isSiteDownTemporarily, setIsSiteDownTemporarily] = useState<boolean>();
    const { data: appSettingsData } = useGetTypedAppSettings({
        paramKey: "isSiteDownTemporarily",
    });
    useEffect(() => {
        if (isUndefined(appSettingsData)) {
            return;
        }

        setIsSiteDownTemporarily(appSettingsData);
    }, [appSettingsData]);

    if (window.location.pathname.startsWith("/settings")) {
        return children;
    }

    if (isUndefined(isSiteDownTemporarily)) {
        return null;
    }

    if (isSiteDownTemporarily) {
        return <SiteDownTemporarily />;
    }

    return children;
};

interface WebPushNotificationWrapperProps {
    children: ReactNode;
}
const WebPushNotificationWrapper = (props: WebPushNotificationWrapperProps) => {
    const { children } = props;

    const [viewNotification] = useViewNotificationMutation();

    const messageChannel = useMemo(() => new MessageChannel(), []);

    useEffect(() => {
        if (Capacitor.getPlatform() === "web" && "serviceWorker" in navigator) {
            navigator.serviceWorker.controller?.postMessage({ action: "portInitialization" }, [messageChannel.port2]);

            messageChannel.port1.onmessage = (event) => {
                if (event.data.action === "webNotificationClicked") {
                    viewNotification({ id: event.data.notificationId });
                }
            };
        }
    }, [messageChannel, viewNotification]);

    return children;
};

const connectionLostDelay = 5_000;
const ignoreAppReloadingRoutes = ["/sign-in", "/registration"];
const AppRouterSwitch = () => {
    const resetApiStates = useResetApiStates();
    const [lostConnectionTimestamp, setLostConnectionTimestamp] = useState<number>();

    useEffect(() => {
        Network.getStatus()
            .then((status) => {
                if (!status.connected) {
                    setLostConnectionTimestamp(new Date().getTime());
                }
            })
            .catch(() => setLostConnectionTimestamp(new Date().getTime()));
    }, []);

    useEffect(() => {
        const addListener = async () => {
            const networkStateListener = await Network.addListener("networkStatusChange", (status) => {
                if (status.connected && lostConnectionTimestamp === undefined) {
                    return;
                }

                if (status.connected && lostConnectionTimestamp !== undefined) {
                    const diff = new Date().getTime() - lostConnectionTimestamp;
                    setLostConnectionTimestamp(undefined);
                    if (diff > connectionLostDelay) {
                        resetApiStates();
                    }

                    return;
                }

                if (!status.connected && status.connectionType === "none") {
                    setLostConnectionTimestamp(new Date().getTime());
                }
            });

            return networkStateListener;
        };

        const addListenerResult = addListener();

        return () => {
            addListenerResult.then((result) => {
                result.remove();
            });
        };
    }, [lostConnectionTimestamp, resetApiStates]);

    const [isConnectionLost, setIsConnectionLost] = useState(false);
    useEffect(() => {
        if (lostConnectionTimestamp === undefined) {
            setIsConnectionLost(false);
            return;
        }

        const timeout = setTimeout(() => {
            setIsConnectionLost(true);
        }, connectionLostDelay);

        return () => clearTimeout(timeout);
    }, [lostConnectionTimestamp]);

    useEffect(() => {
        if (Capacitor.getPlatform() === "web") {
            return;
        }

        const addListener = async () => {
            const resumeListener = await CapacitorApp.addListener("resume", () => {
                if (ignoreAppReloadingRoutes.includes(window.location.pathname)) {
                    return;
                }

                resetApiStates();
            });

            return resumeListener;
        };

        const addListenerResult = addListener();

        return () => {
            addListenerResult.then((result) => result.remove());
        };
    }, [resetApiStates]);

    const children = (
        <SiteDownTemporarilyData>
            <AppWithRouterAndContextProviders />
        </SiteDownTemporarilyData>
    );

    if (isConnectionLost) {
        return <InternetConnectionLost />;
    }

    if (Capacitor.isNativePlatform()) {
        return <UpdatedVersionRouterWithData>{children}</UpdatedVersionRouterWithData>;
    }

    return <WebPushNotificationWrapper>{children}</WebPushNotificationWrapper>;
};

const App = AppRouterSwitch;
export default App;
