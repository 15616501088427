import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { NavigateFunction } from "react-router-dom";

export const openLink = (link: string | undefined, navigate: NavigateFunction): boolean => {
    if (!link) {
        return false;
    }

    if (!link.startsWith("https://")) {
        return false;
    }

    const url = new URL(link);
    const isLocalLink = url.hostname.endsWith("brinx.tv");
    if (isLocalLink) {
        navigate(url.pathname + url.search);
        return true;
    }

    if (Capacitor.isNativePlatform()) {
        Browser.open({ url: link });
        return true;
    }

    window.open(link, "_blank");
    return true;
};
