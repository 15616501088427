import { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./Notification.module.scss";
import "./NotificationNative.scss";
import { CloseIcon } from "../../../assets";
import Logo from "../../../assets/Common/magic-button.svg";
import { isUndefined } from "lodash";
import { useTimeout } from "../../../shared/lib/hooks";

export enum NotificationVariant {
    Left = "Left",
    Center = "Center",
    Right = "Right",
}
interface INotification {
    onClose: () => void;
    notification?: { id: string; value: string };
    textAlign?: NotificationVariant;
    lifetimeMs?: number;
}

const getNotificationVariantClassName = (buttonVariant: NotificationVariant) => {
    switch (buttonVariant) {
        case NotificationVariant.Left:
            return styles.left;

        case NotificationVariant.Center:
            return styles.center;

        case NotificationVariant.Right:
            return styles.right;

        default:
            return styles.left;
    }
};

const Notification = (props: INotification) => {
    const { onClose, notification, textAlign = NotificationVariant.Left, lifetimeMs } = props;

    const [animation, setAnimation] = useState(false);
    useEffect(() => {
        setAnimation(!isUndefined(notification));
    }, [notification]);

    const onCloseHandle = () => {
        setAnimation(false);
        setTimeout(() => onClose(), 500);
    };

    useTimeout(onCloseHandle, lifetimeMs);

    if (!notification) {
        return null;
    }

    return (
        <div className={clsx(styles.notification, "notification-ios", animation ? styles.active : undefined)}>
            <div className={styles.notificationCloseWrapper} onClick={() => onCloseHandle()}>
                <CloseIcon className={styles.notificationCloseIcon} />
            </div>
            <div className={styles.notificationInner}>
                <div className={styles.icon}>
                    <img src={Logo} alt="logo" />
                </div>
                <p className={clsx(styles.notificationText, getNotificationVariantClassName(textAlign))}>
                    {notification.value}
                </p>
            </div>
        </div>
    );
};

export default Notification;
