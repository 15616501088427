import { apiSlice } from "../../shared/api/apiSlice";
import { getQueryUrl } from "../../shared/lib/helpers/api";

interface GetAdminResponseItem {
    id: number;
    email: string;
}

export const adminApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        check: builder.query<boolean, void>({
            query: () => "/admins/check",
        }),
        getAdmins: builder.query<GetAdminResponseItem[], void>({
            query: () => "/admins",
            providesTags: ["Admins"],
        }),
        addAdmin: builder.mutation<number | null, string>({
            query: (email) => ({
                url: getQueryUrl(["admins"]),
                method: "POST",
                body: { email: email },
            }),
            invalidatesTags: ["Admins"],
        }),
        deleteAdmin: builder.mutation<boolean | null, number>({
            query: (id) => ({
                url: getQueryUrl(["admins", id]),
                method: "DELETE",
            }),
            invalidatesTags: ["Admins"],
        }),
    }),
});

export const { useCheckQuery, useGetAdminsQuery, useAddAdminMutation, useDeleteAdminMutation } = adminApiSlice;
