import { v4 as uuid } from "uuid";
import { PropsWithChildren, createContext, useState } from "react";
import Notification from "./Notification";

interface INotificationContext {
    push: (content: string) => void;
}
export const NotificationContext = createContext<INotificationContext>({
    push: () => console.warn("push is not provided"),
});

export const NotificationContextProvider = (props: PropsWithChildren) => {
    const [notifications, setNotifications] = useState<{ id: string; value: string }[]>([]);

    const pushHandle = (content: string) => {
        setNotifications((prev) => [...prev, { id: uuid(), value: content }]);
    };

    const onCloseNotificationHandle = (id: string) => {
        setNotifications((prev) => [...prev.filter((n) => n.id !== id)]);
    };

    return (
        <NotificationContext.Provider
            value={{
                push: (content: string) => pushHandle(content),
            }}
        >
            {props.children}

            {notifications[0] && (
                <Notification
                    key={notifications[0].id}
                    onClose={() => onCloseNotificationHandle(notifications[0].id)}
                    notification={notifications[0]}
                    lifetimeMs={10_000}
                />
            )}
        </NotificationContext.Provider>
    );
};
