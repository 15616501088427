import { isNil, omit } from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import { ModalId, useModalContext } from "./useModalContext";
import "./modal.scss";
import { Capacitor } from "@capacitor/core";

type ModalBaseProps = {
    children?: React.ReactNode;
};

const ModalBase: React.FunctionComponent<ModalBaseProps> = (props) => {
    const { children } = props;

    return <>{children}</>;
};

type ContainerProps = {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
};

type ModalInsideContainerProps = ModalBaseProps & {
    /** @default true */
    showInsideContainer?: boolean;
    ContainerProps?: ContainerProps;
};

const ModalInsideContainer: React.FunctionComponent<ModalInsideContainerProps> = (props) => {
    const NextComponent = ModalBase;
    type NextProps = Parameters<typeof NextComponent>[0];

    const { showInsideContainer = true, ContainerProps } = props;

    const { className: containerClassName = "" } = ContainerProps || {};

    const nextProps: NextProps = omit(props, ["showInsideContainer", "ContainerProps"]);

    return showInsideContainer ? (
        <div
            {...ContainerProps}
            className={`modal-ui-container product-modal-item ${
                Capacitor.getPlatform() === "ios" ? " native-ios" : ""
            } ${containerClassName}`}
        >
            <NextComponent {...nextProps} />
        </div>
    ) : (
        <NextComponent {...nextProps} />
    );
};

type ModalWithPortalProps = ModalInsideContainerProps & {
    /** @default false */
    disablePortal?: boolean;
};

const ModalWithPortal: React.FunctionComponent<ModalWithPortalProps> = (props) => {
    const NextComponent = ModalInsideContainer;
    type NextProps = Parameters<typeof NextComponent>[0];

    type ModalsState = {
        modalIds: ModalId[];
        activeModalId: ModalId | null;
    };

    const { disablePortal = false } = props;

    const [modalsState, setModalsState] = useState<ModalsState>({
        modalIds: [],
        activeModalId: null,
    });

    const nextProps = useMemo<NextProps>(() => omit(props, ["disablePortal"]), [props]);

    const modalContext = useModalContext();

    useEffect(() => {
        if (disablePortal) {
            setModalsState((state) => ({
                ...state,
                activeModalId: null,
            }));
        } else {
            const modalId = modalContext.create(nextProps);

            setModalsState((state) => ({
                modalIds: [...state.modalIds, modalId],
                activeModalId: modalId,
            }));
        }
    }, [disablePortal]);

    useEffect(() => {
        const modalIdsToRemove = modalsState.modalIds.filter((id) => id !== modalsState.activeModalId);

        if (modalIdsToRemove.length === 0) return;

        for (const modelIdToRemove of modalIdsToRemove) {
            modalContext.delete(modelIdToRemove);
        }

        setModalsState((state) => ({
            ...state,
            modalIds: state.modalIds.filter((id) => !modalIdsToRemove.includes(id)),
        }));
    }, [modalsState]);

    useEffect(() => {
        if (isNil(modalsState.activeModalId)) return;

        modalContext.update(modalsState.activeModalId, nextProps);
    }, [modalsState.activeModalId, nextProps]);

    useEffect(
        () => () => {
            for (const modelIdToRemove of modalsState.modalIds) {
                modalContext.delete(modelIdToRemove);
            }
        },
        [],
    );

    return disablePortal ? <NextComponent {...nextProps} /> : <></>;
};

const Modal = ModalWithPortal;
export type ModalProps = Parameters<typeof Modal>[0];

export default Modal;
