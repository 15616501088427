import { PropsWithChildren, createContext, useMemo, useState } from "react";

interface ISearchContext {
    value: string;
    onChangeValue: (value: string) => void;
}

export const SearchContext = createContext<ISearchContext>({
    value: "",
    onChangeValue: () => console.warn("SearchContext: onChangeValue is not provided"),
});

export const SearchContextProvider = (props: PropsWithChildren) => {
    const [value, setValue] = useState("");
    const onChangeValueHandle = (value: string) => {
        setValue(value);
    };
    const providerProps = useMemo(
        () => ({
            value,
            onChangeValue: onChangeValueHandle,
        }),
        [value],
    );

    return <SearchContext.Provider value={providerProps}>{props.children}</SearchContext.Provider>;
};
