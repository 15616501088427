import { useContext, useEffect, useMemo, useState } from "react";
import styles from "./AuthorizationWidget.module.scss";
import { useAppSelector } from "../../app/hooks";
import { selectSharedResourceId } from "../../entities/shareResource/model/shareResourceSlice";
import { Capacitor } from "@capacitor/core";
import { getQueryUrl } from "../../shared/lib/helpers/api";
import { v4 as uuid } from "uuid";
import { scope } from "../../features/auth/constants";
import {
    AppleSignInResponse,
    ClientCommunicationType,
    GoogleSignInResponse,
    IClientCommunicationMessage,
    IClientParams,
} from "../../entities/auth/model/types";
import { useNavigate } from "react-router-dom";
import useAuth from "../../utils/useAuth";
import useNativeAppleSignIn from "../../entities/auth/lib/useNativeAppleSignIn";
import useNativeGoogleSignIn from "../../entities/auth/lib/useNativeGoogleSignIn";
import { ModalContext } from "../../features/UI/Modal/ModalContextProvider";
import SubmittedIcon from "../../assets/Common/round-check-mark--success.svg";
import Loader from "../../shared/ui/Loader/Loader";
import { NotificationContext } from "../../features/UI/Notification/NotificationContextProvider";
import { selectAccessToken } from "../../shared/lib/authSlice";

interface AuthorizationWidgetProps {
    route: string;
}
const AuthorizationWidget = (props: AuthorizationWidgetProps) => {
    const { route } = props;

    const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    const { push } = useContext(NotificationContext);
    const { open } = useContext(ModalContext);
    const navigate = useNavigate();
    const { login } = useAuth();
    const accessToken = useAppSelector(selectAccessToken);

    useEffect(() => {
        if (accessToken) {
            navigate("/");
        }
    }, [accessToken, navigate]);

    const sharedResourceId = useAppSelector(selectSharedResourceId);
    const clientParams = useMemo((): IClientParams => {
        return {
            clientId: process.env.REACT_APP_IDP_CLIENT_ID ?? "",
            responseType: "code",
            redirectUri: (process.env.REACT_APP_WEB_FRONT_URL ?? "") + "/login",
            scope,
            state: uuid(),
            sharedResourceId,
            options: {
                ssoButtons: { apple: Capacitor.getPlatform() !== "android" },
                platform: Capacitor.getPlatform(),
            },
        };
    }, [sharedResourceId]);

    const signInWithApple = useNativeAppleSignIn();
    const signInWithGoogle = useNativeGoogleSignIn();

    const [nativeSignInResponse, setNativeSignInResponse] = useState<
        GoogleSignInResponse | AppleSignInResponse | undefined
    >();

    const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
    useEffect(() => {
        if (!nativeSignInResponse) {
            return;
        }

        let communicationType;
        if (nativeSignInResponse.provider === "google") {
            communicationType = ClientCommunicationType.GoogleSignInResponse;
        }

        if (nativeSignInResponse.provider === "apple") {
            communicationType = ClientCommunicationType.AppleSignInResponse;
        }

        contentRef?.contentWindow?.postMessage(
            {
                type: communicationType,
                response: nativeSignInResponse,
            } as IClientCommunicationMessage,
            process.env.REACT_APP_IDP_URL ?? "",
        );
    }, [contentRef?.contentWindow, nativeSignInResponse]);

    useEffect(() => {
        const eventListenerCallback = (event: MessageEvent<any>) => {
            if (event.data.type === ClientCommunicationType.SignIn) {
                login(event.data.authorizationCode);
                return;
            }

            if (event.data.type === ClientCommunicationType.ForgotPassword) {
                push("An email with a link to reset your password has been sent. Open it to continue");

                contentRef?.contentWindow?.postMessage(
                    {
                        type: ClientCommunicationType.BackAction,
                    } as IClientCommunicationMessage,
                    process.env.REACT_APP_IDP_URL ?? "",
                );
                return;
            }

            if (event.data.type === ClientCommunicationType.SomethingWentWrongError) {
                push("An error has occured. Please, try again or contact support.");

                contentRef?.contentWindow?.postMessage(
                    {
                        type: ClientCommunicationType.BackAction,
                    } as IClientCommunicationMessage,
                    process.env.REACT_APP_IDP_URL ?? "",
                );
                return;
            }

            if (event.data.type === ClientCommunicationType.SignUpConfirmation) {
                contentRef?.contentWindow?.postMessage(
                    {
                        type: ClientCommunicationType.BackAction,
                    } as IClientCommunicationMessage,
                    process.env.REACT_APP_IDP_URL ?? "",
                );
                open(
                    {},
                    <div className={styles.modal}>
                        <div className={styles.modalHeader}>
                            <p className={styles.modalTitle}>SUCCESS</p>
                            <img className={styles.modalIcon} src={SubmittedIcon} alt="icon" />
                        </div>
                        <p className={styles.modalText}>
                            A confirmation email has been sent. Open it to complete your registration.
                        </p>
                    </div>,
                );
                return;
            }

            if (event.data.type === ClientCommunicationType.GoogleSignIn) {
                signInWithGoogle().then((result) => {
                    if (!result) {
                        return;
                    }

                    setNativeSignInResponse({
                        provider: "google",
                        credential: result.authentication.idToken,
                    });
                });

                return;
            }

            if (event.data.type === ClientCommunicationType.AppleSignIn) {
                signInWithApple().then((result) => {
                    if (!result) {
                        return;
                    }

                    setNativeSignInResponse({
                        provider: "apple",
                        authorization: {
                            code: result.response.authorizationCode,
                            id_token: result.response.identityToken,
                        },
                        user: {
                            email: result.response.email,
                            name: {
                                firstName: result.response.givenName,
                                lastName: result.response.familyName,
                            },
                        },
                    });
                });
            }
        };

        window.addEventListener("message", eventListenerCallback);

        return () => {
            window.removeEventListener("message", eventListenerCallback);
        };
    }, [contentRef?.contentWindow, login, open, push, signInWithApple, signInWithGoogle]);

    return (
        <>
            {!isIframeLoaded ? <Loader /> : null}
            <iframe
                ref={setContentRef}
                title="Continue with BrinxTv"
                src={getQueryUrl([process.env.REACT_APP_IDP_URL ?? "", route], { ...clientParams })}
                height="100%"
                style={{ display: isIframeLoaded ? "block" : "none" }}
                onLoad={() => {
                    setIsIframeLoaded(true);
                }}
            />
        </>
    );
};

export default AuthorizationWidget;
