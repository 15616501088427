import { useEffect } from "react";

export const usePageTitle = (value: string) => {
    useEffect(() => {
        document.title = `${value} · Brinx.TV`;
        return () => {
            document.title = "Brinx.TV";
        };
    }, [value]);
};
