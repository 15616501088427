import { omit } from "lodash";
import React, { Fragment } from "react";
import Modal, { ModalProps } from "../Modals/Modal";
import Paper, { PaperProps } from "../../UI/Papers/Paper";
import "./dialog.scss";
import { CloseIcon } from "../../../assets";

type DialogBaseProps = Omit<ModalProps, "showInsideContainer" | "disablePortal" | "ContainerProps"> & {
    /** @default false */
    open?: boolean;
    onClose?: () => void;
    PaperProps?: Omit<PaperProps, "children">;
    ContainerProps?: Omit<NonNullable<ModalProps["ContainerProps"]>, "onClick">;
};

const DialogBase: React.FunctionComponent<DialogBaseProps> = (props) => {
    const { open = false, onClose, ContainerProps, children, PaperProps: PaperPropsFromProps } = props;

    const ModalProps: ModalProps = {
        ...omit(props, ["PaperProps", "children"]),
        ContainerProps: {
            ...ContainerProps,
            onClick: onClose,
            className: `dialog-ui ${open ? "dialog-ui-opened" : "dialog-ui-hidden"} ${ContainerProps?.className ?? ""}`,
        },
    };

    const PaperProps: PaperProps = {
        ...PaperPropsFromProps,
        variant: PaperPropsFromProps?.variant ?? "elevation",
        elevation: PaperPropsFromProps?.elevation ?? 0,
        children: children,
    };

    return (
        <Modal {...ModalProps}>
            <Paper {...PaperProps} />
        </Modal>
    );
};

type DialogWithCloseButtonProps = DialogBaseProps & {
    /** @default true */
    showCloseButton?: boolean;
};

const DialogWithCloseButton: React.FunctionComponent<DialogWithCloseButtonProps> = (props) => {
    const NextComponent = DialogBase;
    type NextProps = Parameters<typeof NextComponent>[0];

    const { showCloseButton = true, children, onClose } = props;

    const nextProps: NextProps = {
        ...omit(props, ["showCloseButton", "children"]),
    };

    return showCloseButton ? (
        <NextComponent {...nextProps}>
            <Fragment>
                {children}

                <button
                    className="dialog-close-button"
                    children={<CloseIcon className="dialog-close-icon" />}
                    onClick={onClose}
                />
            </Fragment>
        </NextComponent>
    ) : (
        <NextComponent {...nextProps} children={children} />
    );
};

const Dialog = DialogWithCloseButton;
export type DialogProps = Parameters<typeof Dialog>[0];

export default Dialog;
