import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SwiperState } from "./types";

interface IRedeemState {
    swiperStates: SwiperState[] | undefined;
    lastSelectedLevel: string | undefined;
}

let initialState: IRedeemState = { swiperStates: undefined, lastSelectedLevel: undefined };

export const redeemSlice = createSlice({
    name: "redeem",
    initialState,
    reducers: {
        saveSwiperStates(state, action: PayloadAction<SwiperState[]>) {
            state.swiperStates = action.payload;
        },
        setLastSelectedLevel(state, action: PayloadAction<string | undefined>) {
            state.lastSelectedLevel = action.payload;
        },
    },
});

export const selectSwiperStates = (state: RootState) => state.redeem.swiperStates;
export const selectLastSelectedLevel = (state: RootState) => state.redeem.lastSelectedLevel;

export const { saveSwiperStates, setLastSelectedLevel } = redeemSlice.actions;
