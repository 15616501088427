import _ from "lodash";
import { useMemo, useEffect, useCallback, useState } from "react";

export type ConvertValue<TValue, TChangedValue> = (value: TChangedValue) => TValue | ((prev: TValue) => TValue);

type ConvertedValue<TValue, TChangedValue> = TChangedValue extends TValue
    ? {
          /** @default (value) => value */
          onConvertValue?: ConvertValue<TValue, TChangedValue>;
      }
    : {
          onConvertValue: ConvertValue<TValue, TChangedValue>;
      };

type useLocalStateProps<TValue, TChangedValue> = {
    valueFromProps?: TValue;
    onChangeFromProps?: (value: TChangedValue) => void;
    defaultValue?: TValue;
} & ConvertedValue<TValue, TChangedValue>;

export const useLocalState = <TValue, TChangedValue = TValue>(props: useLocalStateProps<TValue, TChangedValue>) => {
    const { valueFromProps, onChangeFromProps, defaultValue, onConvertValue: onConvertValueFromProps } = props;

    const isControlled = !_.isUndefined(valueFromProps);

    const [valueLocal, setValueLocal] = useState<TValue | undefined>(defaultValue);

    const onConvertValue = useMemo(
        () => onConvertValueFromProps ?? ((value: TChangedValue) => value as any),
        [onConvertValueFromProps],
    );

    const value = useMemo(
        () => (isControlled ? valueFromProps : valueLocal),
        [isControlled, valueLocal, valueFromProps],
    );

    const setValue = useCallback(
        (value: TChangedValue) => {
            if (!isControlled) {
                const convertedValue = onConvertValue(value);
                setValueLocal(convertedValue);
            }

            onChangeFromProps?.(value);
        },
        [isControlled, onChangeFromProps, onConvertValue],
    );

    useEffect(() => {
        isControlled && setValueLocal(valueFromProps);
    }, [isControlled, valueFromProps]);

    useEffect(() => {
        _.isUndefined(valueLocal) && setValueLocal((p) => (_.isUndefined(p) ? defaultValue : p));
    }, [defaultValue, valueLocal]);

    return [value, setValue] as const;
};
