import { SignInWithApple, SignInWithAppleOptions } from "@capacitor-community/apple-sign-in";
import { Capacitor } from "@capacitor/core";

const useNativeAppleSignIn = () => {
    const signInWithApple = async () => {
        if (Capacitor.getPlatform() !== "ios") {
            return;
        }

        let options: SignInWithAppleOptions = {
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID ?? "",
            redirectURI: `${process.env.REACT_APP_WEB_FRONT_URL}/login/apple`,
            scopes: "name email",
        };

        const signInResult = await SignInWithApple.authorize(options);
        return signInResult;
    };

    return signInWithApple;
};

export default useNativeAppleSignIn;
