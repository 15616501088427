import { PropsWithChildren, ReactElement, createContext, useState } from "react";
import Modal, { IModalOptions } from "./Modal";

interface IModalContext {
    open: (options: IModalOptions, modalContent: ReactElement) => void;
    close: () => void;
    update: (options: IModalOptions, modalContent?: ReactElement) => void;
}
export const ModalContext = createContext<IModalContext>({
    open: () => console.warn("open is not provided"),
    close: () => console.warn("close is not provided"),
    update: () => console.warn("update is not provided"),
});

export const ModalContextProvider = (props: PropsWithChildren) => {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState<IModalOptions>({});
    const [modalContent, setModalContent] = useState<ReactElement>();

    const openHandle = (options: IModalOptions, modalContent: ReactElement) => {
        setOptions(options);
        setModalContent(modalContent);
        setIsOpen(true);
    };

    const updateHandle = (options: IModalOptions, modalContent?: ReactElement) => {
        setOptions(options);
        if (modalContent) setModalContent(modalContent);
    };

    return (
        <ModalContext.Provider
            value={{
                open: (options: IModalOptions, modalContent: ReactElement) => openHandle(options, modalContent),
                close: () => setIsOpen(false),
                update: (options: IModalOptions, modalContent?: ReactElement) => updateHandle(options, modalContent),
            }}
        >
            {props.children}
            <Modal isModalShown={isOpen} onModalShownClose={() => setIsOpen(false)} options={options}>
                {modalContent}
            </Modal>
        </ModalContext.Provider>
    );
};
