import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react";
import {
    GetCartResponse,
    useAddCartProductMutation,
    useClearCartMutation,
    useCreateCartMutation,
    useGetCartQuery,
    useGetUnauthorizedCartQuery,
} from "../../../features/shop/shopApiSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { NotificationContext } from "../../../features/UI/Notification/NotificationContextProvider";
import { saveUnauthorizedCartId, selectUnauthorizedCartId } from "./unauthorizedCartSlice";
import { selectAccessToken } from "../../../shared/lib/authSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { apiSlice } from "../../../shared/api/apiSlice";
import BigNumber from "bignumber.js";
import { useAddRedeemCartProductMutation } from "../../../features/redeem/redeemApiSlice";

interface IShopCartContextProvider {
    shopCart: GetCartResponse | undefined;
    resetShopCart: () => void;
    onAddCartProduct: (
        productVariantId: string,
        quantity: number,
        brinxBuxForUnit: BigNumber,
        shareResourceId?: string,
        onAdded?: () => void,
    ) => void;
    isAddCartProductLoading: boolean;
    onAddCartRedeemProduct: (
        productVariantId: string,
        quantity: number,
        collectionId: string,
        shareResourceId?: string,
    ) => void;
    isAddCartRedeemProductLoading: boolean;
    onClearCart: () => Promise<boolean | void>;
    isClearCartLoading: boolean;
}
export const ShopCartContext = createContext<IShopCartContextProvider>({
    shopCart: undefined,
    resetShopCart: () => console.warn("resetShopCart in not provided"),
    onAddCartProduct: () => console.warn("onAddCartProduct in not provided"),
    isAddCartProductLoading: false,
    onAddCartRedeemProduct: () => console.warn("onAddCartRedeemProduct in not provided"),
    isAddCartRedeemProductLoading: false,
    onClearCart: async () => console.warn("onClearCart in not provided"),
    isClearCartLoading: false,
});

export const ShopCartContextProvider = (props: PropsWithChildren) => {
    const dispatch = useAppDispatch();
    const { push } = useContext(NotificationContext);

    const cartId = useAppSelector(selectUnauthorizedCartId);
    const [shopCart, setShopCart] = useState<GetCartResponse>();
    const accessToken = useAppSelector(selectAccessToken);

    const [createCartMutation] = useCreateCartMutation();
    const createCart = useCallback(async () => {
        try {
            const createCartResult = await createCartMutation().unwrap();
            if (!accessToken) {
                dispatch(saveUnauthorizedCartId(createCartResult.id));
            }

            return createCartResult;
        } catch {
            console.log("Failed to create cart.");
        }
    }, [accessToken, createCartMutation, dispatch]);

    const {
        data: authorizedShopCart,
        isError: isAuthorizedShopCartError,
        error: authorizedShopCartError,
    } = useGetCartQuery(accessToken ? undefined : skipToken, { refetchOnMountOrArgChange: true });
    const {
        data: unauthorizedShopCart,
        isError: isUnauthorizedShopCartError,
        error: unauthorizedShopCartError,
    } = useGetUnauthorizedCartQuery(!accessToken ? cartId : skipToken, { refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (
            (accessToken &&
                isAuthorizedShopCartError &&
                "status" in authorizedShopCartError &&
                authorizedShopCartError.status === 404) ||
            (!accessToken &&
                isUnauthorizedShopCartError &&
                "status" in unauthorizedShopCartError &&
                unauthorizedShopCartError.status === 404)
        ) {
            createCart().then((createCartResult) => {
                if (createCartResult) {
                    setShopCart(createCartResult);
                } else {
                    push("Failed to create cart, please try again later.");
                }
            });
            return;
        }

        setShopCart(accessToken ? authorizedShopCart : unauthorizedShopCart);
    }, [
        accessToken,
        authorizedShopCart,
        authorizedShopCartError,
        createCart,
        isAuthorizedShopCartError,
        isUnauthorizedShopCartError,
        push,
        unauthorizedShopCart,
        unauthorizedShopCartError,
    ]);

    const resetShopCart = useCallback(() => {
        dispatch(apiSlice.util.invalidateTags(["CartProducts"]));
        dispatch(saveUnauthorizedCartId(null));
    }, [dispatch]);

    const [addCartProduct, { isLoading: isAddCartProductLoading }] = useAddCartProductMutation();
    const onAddCartProduct = useCallback(
        (
            productVariantId: string,
            quantity: number,
            brinxBuxForUnit: BigNumber,
            shareResourceId?: string,
            onAdded?: () => void,
        ) => {
            if (quantity <= 0) {
                console.warn("quantity <= 0");
                return;
            }

            if (brinxBuxForUnit.isLessThan(0)) {
                console.warn("brinxBuxForUnit < 0");
                return;
            }

            if (!cartId && !shopCart?.id) {
                console.warn("User cart does not exist.");
                return;
            }

            addCartProduct({
                cartId: cartId ?? shopCart!.id,
                productVariantId,
                quantity,
                shareResourceId,
            })
                .unwrap()
                .then(() => onAdded?.())
                .catch((e) => {
                    console.error(e);
                    push("Not enough BB!");
                });
        },
        [addCartProduct, cartId, push, shopCart],
    );

    const [addCartRedeemProduct, { isLoading: isAddCartRedeemProductLoading }] = useAddRedeemCartProductMutation();
    const onAddCartRedeemProduct = useCallback(
        (productVariantId: string, quantity: number, collectionId: string, shareResourceId?: string) => {
            if (quantity <= 0) {
                console.warn("quantity <= 0");
                return;
            }

            addCartRedeemProduct({
                productVariantId,
                quantity,
                collectionId,
                shareResourceId,
            })
                .unwrap()
                .catch((e) => console.error(e));
        },
        [addCartRedeemProduct],
    );

    const [clearCart, { isLoading: isClearCartLoading }] = useClearCartMutation();
    const onClearCart = useCallback(async () => {
        if (!cartId && !shopCart?.id) {
            console.warn("User cart does not exist.");
            return false;
        }

        const result = await clearCart(cartId ?? shopCart!.id)
            .unwrap()
            .catch((e) => {
                console.error(e);
                return false;
            });

        return result;
    }, [cartId, clearCart, shopCart]);

    return (
        <ShopCartContext.Provider
            value={{
                shopCart,
                resetShopCart,
                onAddCartProduct,
                isAddCartProductLoading,
                onAddCartRedeemProduct,
                isAddCartRedeemProductLoading,
                onClearCart,
                isClearCartLoading,
            }}
        >
            {props.children}
        </ShopCartContext.Provider>
    );
};
