import { createContext, PropsWithChildren, useCallback, useMemo, useState } from "react";
import FeatureType from "../model/featureType";
import { useGetTypedAppSettings } from "../../appSettings/api/appSettingsApiSlice";
import { FeaturePalette } from "../../appSettings/api/types";

interface IFeatureContext {
    onSelectFeature: (feature?: FeatureType) => void;
    featurePalette: FeaturePalette;
}

export const FeatureContext = createContext<IFeatureContext | undefined>(undefined);

export const FeatureProvider = (props: PropsWithChildren) => {
    const [selectedFeature, setSelectedFeature] = useState<FeatureType>();

    const onSelectFeatureHandle = useCallback((feature?: FeatureType) => {
        setSelectedFeature(feature);
    }, []);

    const { data: themeData } = useGetTypedAppSettings({
        paramKey: "theme",
    });

    const featurePalette = useMemo(() => {
        if (selectedFeature) {
            const palette = themeData?.palette?.[selectedFeature];
            if (palette) {
                return palette;
            }
        }

        return {
            main: getComputedStyle(document.documentElement).getPropertyValue("--clr-primary"),
        };
    }, [selectedFeature, themeData?.palette]);

    return (
        <FeatureContext.Provider
            value={{
                onSelectFeature: onSelectFeatureHandle,
                featurePalette: featurePalette,
            }}
        >
            {props.children}
        </FeatureContext.Provider>
    );
};
