type NativeSignInResponse = {
    provider: "google" | "apple";
};

export type AppleSignInResponse = NativeSignInResponse & {
    authorization: {
        code: string;
        id_token: string;
        state?: string;
    };
    user?: {
        email?: string | null;
        name?: {
            firstName?: string | null;
            lastName?: string | null;
        };
    };
    error?: unknown;
};

export type GoogleSignInResponse = NativeSignInResponse & {
    credential: string;
};

export interface IClientParams {
    clientId: string;
    responseType: OAuthResponseType;
    redirectUri: string;
    scope: string;
    state: string | null;
    sharedResourceId: string | null;
    options?: ISsoOptions;
}

export const oAuthResponseTypes = ["code"] as const;
export type OAuthResponseType = (typeof oAuthResponseTypes)[number];

export const scopes = ["openid", "profile", "email"] as const;
export type Scope = (typeof scopes)[number];

export interface ISsoOptions {
    ssoButtons?: {
        google?: boolean;
        amazon?: boolean;
        apple?: boolean;
    };
    platform?: string;
}

export interface ICompleteParams {
    token: string;
    email: string;
    authCode: string;
}

export enum ClientCommunicationType {
    SignIn = "signIn",
    SignUp = "signUp",
    SignUpConfirmation = "signUpConfirmation",
    GoogleSignIn = "googleSignIn",
    GoogleSignInResponse = "googleSignInResponse",
    AppleSignIn = "appleSignIn",
    AppleSignInResponse = "appleSignInResponse",
    ForgotPassword = "forgotPassword",
    BackAction = "backAction",
    SomethingWentWrongError = "somethingWentWrongError",
}

export interface IClientCommunicationMessage {
    type: ClientCommunicationType;
    [key: string]: unknown;
}

export interface IClientAuthorizationCommunicationMessage extends IClientCommunicationMessage {
    authorizationCode: string;
    userId: string;
    state: string | null;
}
