import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SwiperState } from "./types";

interface IShopState {
    swiperStates: SwiperState[] | undefined;
    lastSelectedAccordion: string | undefined;
}

let initialState: IShopState = {
    swiperStates: undefined,
    lastSelectedAccordion: undefined,
};

export const shopSlice = createSlice({
    name: "shop",
    initialState,
    reducers: {
        saveSwiperStates(state, action: PayloadAction<SwiperState[]>) {
            state.swiperStates = action.payload;
        },
        setLastSelectedAccordion(state, action: PayloadAction<string | undefined>) {
            state.lastSelectedAccordion = action.payload;
        },
    },
});

export const selectSwiperStates = (state: RootState) => state.shop.swiperStates;
export const selectLastSelectedAccordion = (state: RootState) => state.shop.lastSelectedAccordion;

export const { saveSwiperStates, setLastSelectedAccordion } = shopSlice.actions;
