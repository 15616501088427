import { fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi } from "@reduxjs/toolkit/query/react";
import { getQueryUrl } from "../lib/helpers/api";

const rawBaseQuery = fetchBaseQuery({
    baseUrl: "https://api.zype.com/",
});

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    const apiKey = process.env.REACT_APP_ZYPE_API_KEY;
    if (!apiKey) {
        return {
            error: {
                status: 400,
                statusText: "Bad Request",
                data: "Zype API Key is not provided",
            },
        };
    }

    const urlEnd = typeof args === "string" ? args : args.url;
    const adjustedUrl = getQueryUrl(
        urlEnd,
        typeof args === "string"
            ? {
                  api_key: apiKey,
              }
            : {
                  api_key: apiKey,
                  ...args.params,
              },
    );
    const adjustedArgs = typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
    return rawBaseQuery(adjustedArgs, api, extraOptions);
};

export const zypeExternalApiSlice = createApi({
    reducerPath: "zypeApi",
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({}),
    tagTypes: ["Playlists", "VideoList", "Categories"],
});
