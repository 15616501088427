import { has, omit, values } from "lodash";
import React, { useState, useCallback, useRef, useMemo, useContext } from "react";
import Modal, { ModalProps } from "./Modal";

export type ModalId = number | string;

type ModalContextValue = {
    readonly create: (props: ModalProps) => ModalId;
    readonly update: (id: ModalId, props: ModalProps) => void;
    readonly delete: (id: ModalId) => void;
};

export const ModalContext = React.createContext<ModalContextValue>({
    create: () => {
        throw "Not provided modal create";
    },
    update: () => {
        throw "Not provided modal update";
    },
    delete: () => {
        throw "Not provided modal delete";
    },
});

type ModalDescription = {
    id: ModalId;
    props: ModalProps;
};

export type ModalProviderProps = {
    children?: React.ReactNode;
};

export const ModalProvider: React.FunctionComponent<ModalProviderProps> = (props) => {
    const { children } = props;

    const [modalsById, setModalsById] = useState<{ [id: ModalId]: ModalDescription }>({});
    const nextIndexRef = useRef<number>(0);

    const modals = useMemo(() => values(modalsById), [modalsById]);

    const createHandle = useCallback<ModalContextValue["create"]>((props) => {
        const modalId = nextIndexRef.current++;

        setModalsById((modals) => {
            if (has(modals, modalId)) throw "Modal id is already reserved";

            return {
                ...modals,
                [modalId]: { id: modalId, props },
            };
        });

        return modalId;
    }, []);

    const updateHandle = useCallback<ModalContextValue["update"]>((id, props) => {
        setModalsById((modals) => {
            if (!has(modals, id)) return modals;

            return {
                ...modals,
                [id]: { id, props },
            };
        });
    }, []);

    const deleteHandle = useCallback<ModalContextValue["delete"]>((id) => {
        setModalsById((modals) => omit(modals, [id]));
    }, []);

    const providerProps = useMemo<React.ProviderProps<ModalContextValue>>(
        () => ({
            value: {
                create: createHandle,
                update: updateHandle,
                delete: deleteHandle,
            },
        }),
        [createHandle, updateHandle, deleteHandle],
    );

    return (
        <ModalContext.Provider {...providerProps}>
            {children}
            {modals.map((m) => (
                <Modal key={m.id} {...m.props} disablePortal />
            ))}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => useContext(ModalContext);
