import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store/store";
import App from "./app/App";
import ErrorBoundary from "./app/providers/ErrorBoundary";
import CriticalErrorMessage from "./pages/errors/CriticalErrorMessage/CriticalErrorMessage";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <ErrorBoundary fallback={<CriticalErrorMessage />}>
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundary>,
);
