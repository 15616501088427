import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IShareResourceState {
    sharedResourceId: string | null;
}

let initialState: IShareResourceState = {
    sharedResourceId: localStorage.getItem("SharedResourceId"),
};

export const shareResourceSlice = createSlice({
    name: "shareResource",
    initialState,
    reducers: {
        setSharedResourceId(state, action: PayloadAction<string>) {
            state.sharedResourceId = action.payload;
            localStorage.setItem("SharedResourceId", action.payload);
        },
    },
});

export const selectSharedResourceId = (state: RootState) => state.shareResource.sharedResourceId;

export const { setSharedResourceId } = shareResourceSlice.actions;
