import { usePageTitle } from "../../shared/lib/hooks";
import AuthorizationWidget from "../../widgets/auth/AuthorizationWidget";

interface AuthenticationProps {
    route: string;
}
const Authentication = (props: AuthenticationProps) => {
    const { route } = props;

    usePageTitle("Authentication");

    return <AuthorizationWidget route={route} />;
};

export default Authentication;
