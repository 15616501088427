import _ from "lodash";

export const clientCommunicateMessageTypes = [
    { id: 2, key: "auctionBidUpdated" },
    { id: 5, key: "subscriptionUpdated" },
    { id: 6, key: "paymentIntentUpdated" },
    { id: 7, key: "shopCartUpdated" },
    { id: 8, key: "brinxBuxLevelUpdated" },
    { id: 9, key: "redeemProductUpdated" },
    { id: 10, key: "notificationCreated" },
] as const;

export type ClientCommunicateMessageTypes = typeof clientCommunicateMessageTypes;
export type ClientCommunicateMessageType = ClientCommunicateMessageTypes[number];
export type ClientCommunicateMessageTypeId = ClientCommunicateMessageType["id"];
export type ClientCommunicateMessageTypeKey = ClientCommunicateMessageType["key"];
export type ClientCommunicateMessageTypeById<TId extends ClientCommunicateMessageTypeId> = Extract<
    ClientCommunicateMessageType,
    { id: TId }
>;
export type ClientCommunicateMessageTypeByKey<TKey extends ClientCommunicateMessageTypeKey> = Extract<
    ClientCommunicateMessageType,
    { key: TKey }
>;
export type ClientCommunicateMessageTypeIds = {
    [key in ClientCommunicateMessageTypeKey]: ClientCommunicateMessageTypeByKey<key>["id"];
};

export const clientCommunicateMessageTypeIds = _.reduce(
    clientCommunicateMessageTypes,
    (r, g) => ({ ...r, [g.key]: g.id }),
    {},
) as ClientCommunicateMessageTypeIds;

export const isClientCommunicateMessageTypeId = (value: any): value is ClientCommunicateMessageTypeId =>
    clientCommunicateMessageTypes.some((t) => t.id === value);

export const isClientCommunicateMessageTypeKey = (value: any): value is ClientCommunicateMessageTypeKey =>
    clientCommunicateMessageTypes.some((t) => t.key === value);

export const getClientCommunicateMessageTypeByKey = <TKey extends ClientCommunicateMessageTypeKey>(key: TKey) =>
    clientCommunicateMessageTypes.find((t) => t.key === key) as ClientCommunicateMessageTypeByKey<TKey>;

export const getClientCommunicateMessageTypeById = <TId extends ClientCommunicateMessageTypeId>(id: TId) =>
    clientCommunicateMessageTypes.find((r) => r.id === id) as ClientCommunicateMessageTypeById<TId>;
