import { useCallback } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { apiSlice } from "../../api/apiSlice";
import { shopifyApiSlice } from "../../api/shopifyApiSlice";
import { zypeExternalApiSlice } from "../../api/zypeExternalApiSlice";

const useResetApiStates = () => {
    const dispatch = useAppDispatch();

    const resetApiStates = useCallback(() => {
        dispatch(apiSlice.util.resetApiState());
        dispatch(shopifyApiSlice.util.resetApiState());
        dispatch(zypeExternalApiSlice.util.resetApiState());
    }, [dispatch]);

    return resetApiStates;
};

export default useResetApiStates;
