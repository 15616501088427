import { isNil } from "lodash";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useCheckQuery } from "./adminApiSlice";

export type ProtectedAdminRouteProps = {
    redirectPath?: string;
    replace?: boolean;
    children: JSX.Element;
};

export const ProtectedAdminRoute = (props: ProtectedAdminRouteProps) => {
    const { redirectPath = "/404", replace = false, children } = props;

    const { data: isAdmin, isLoading } = useCheckQuery();
    if (isLoading) {
        return null;
    }

    if (isNil(isAdmin) || isAdmin !== true) {
        return <Navigate to={redirectPath} replace={replace} />;
    }

    return children ? children : <Outlet />;
};
