import { isArray, isEmpty, isNil, isString, trim } from "lodash";

type PathItem = string | number | undefined | null;

export const getQueryApiUrl = (path: PathItem | PathItem[], data?: { [s: string]: any } | undefined | null) => {
    const url = getQueryUrl(path, data);
    return `${process.env.REACT_APP_BACK_URL}/${url}`;
};

export const getQueryUrl = (path: PathItem | PathItem[], data?: { [s: string]: any } | undefined | null) => {
    let url = getUrl(path);

    if (isEmpty(data)) {
        return url;
    }

    const getParams = (
        data: {
            [s: string]: any;
        },
        outerKey?: string,
    ): string =>
        Object.entries(data)
            .filter(([, val]) => !isNil(val))
            .map(([key, val]) => {
                const newKey = outerKey ? `${outerKey}.${key}` : key;
                if (isArray(val)) {
                    return Object.entries(val)
                        .map(([_, v]) =>
                            v !== null && typeof v === "object"
                                ? getParams(v, newKey)
                                : `${newKey}=${encodeURIComponent(v)}`,
                        )
                        .join("&");
                } else {
                    return val !== null && typeof val === "object"
                        ? getParams(val, newKey)
                        : `${newKey}=${encodeURIComponent(val)}`;
                }
            })
            .join("&");

    const params = getParams(data);

    if (isEmpty(params)) return url;

    url = `${url}${!url.includes("?") ? "?" : "&"}${params}`;

    return url;
};

const getUrl = (path: PathItem | PathItem[]) => {
    if (isArray(path)) {
        path = path
            .filter((s) => !isNil(s) && (!isString(s) || !isEmpty(s)))
            .map((s) => (isString(s) ? trim(s, "/") : s))
            .join("/");
    }

    const url = (path ?? "").toString();
    return url;
};
