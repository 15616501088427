import { omit } from "lodash";
import React, { useCallback } from "react";
import "./paper.css";

type PaperBaseProps = {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    onClick?: () => void;
};

const PaperBase: React.FunctionComponent<PaperBaseProps> = (props) => {
    const { className = "", style, children, onClick } = props;

    const onDivClickHandle = useCallback<React.MouseEventHandler<HTMLDivElement>>(
        (event) => {
            onClick?.();
            event.stopPropagation();
        },
        [onClick],
    );

    return <div className={`paper-ui ${className}`} style={style} children={children} onClick={onDivClickHandle} />;
};

const variantToClassName: { [k in NonNullable<PaperWithVariantsProps["variant"]>]: string } = {
    outlined: "paper-outlined",
    elevation: "paper-elevation",
};

const elevationToClassName: { [k in NonNullable<PaperWithVariantsProps["elevation"]>]: string } = {
    0: "paper-elevation0",
    1: "paper-elevation1",
    2: "paper-elevation2",
    3: "paper-elevation3",
    4: "paper-elevation4",
    5: "paper-elevation5",
    6: "paper-elevation6",
    7: "paper-elevation7",
    8: "paper-elevation8",
};

type PaperWithVariantsProps = PaperBaseProps & {
    /** @default "outlined" */
    variant?: "outlined" | "elevation";
    /** 
       Shadow depth. It accepts values between 0 and 8 inclusive
       @default 1
    */
    elevation?: number;
};

const PaperWithVariants: React.FunctionComponent<PaperWithVariantsProps> = (props) => {
    const NextComponent = PaperBase;
    type NextProps = Parameters<typeof NextComponent>[0];

    const { variant = "outlined", elevation = 1, className: classNameFromProps = "" } = props;

    const variantClassName = variantToClassName[variant] ?? "";
    const elevationClassName = elevationToClassName[elevation] ?? "";

    const nextProps: NextProps = {
        ...omit(props, ["variant", "elevation"]),
        className: `${variantClassName} ${elevationClassName} ${classNameFromProps}`,
    };

    return NextComponent(nextProps);
};

const Paper = PaperWithVariants;
export type PaperProps = Parameters<typeof Paper>[0];

export default Paper;
