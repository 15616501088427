import clsx from "clsx";
import styles from "./CriticalErrorMessage.module.scss";
import "./CriticalErrorMessage.scss";
import image from "../../../assets/UpdateScreen/Alpaca.svg";
import Button, { ButtonVariant } from "../../../shared/ui/buttons/Button/Button";
import GlobalError from "../GlobalError";

const CriticalErrorMessage = () => {
    const reloadPage = () => window.location.reload();

    return (
        <GlobalError>
            <div className={clsx(styles.wrapper, "page-critical-error")}>
                <div className={styles.row}></div>
                <div className={styles.inner}>
                    <img className={styles.image} src={image} alt="critical message" />
                    <div className={styles.center}>
                        <p className={styles.text}>Something went wrong.</p>
                        <p className={clsx(styles.text, styles.divider)}>Please, try again!</p>
                    </div>
                    <Button
                        className={styles.btn}
                        buttonVariant={ButtonVariant.Yellow}
                        title="RETRY"
                        onClick={reloadPage}
                    />
                </div>
                <div className={styles.row}></div>
            </div>
        </GlobalError>
    );
};

export default CriticalErrorMessage;
