import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";
import { isUndefined } from "lodash";

interface IProfileState {
    activeAccordions: Array<string>;
    brinxBuxAmount: string | undefined;
}

let initialState: IProfileState = { activeAccordions: new Array<string>(), brinxBuxAmount: undefined };

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        toggleAccordion(state, action: PayloadAction<string>) {
            if (state.activeAccordions.includes(action.payload)) {
                state.activeAccordions = state.activeAccordions.filter((a) => a !== action.payload);
            } else {
                state.activeAccordions.push(action.payload);
            }
        },
        setBrinxBux(state, action: PayloadAction<string | undefined>) {
            state.brinxBuxAmount = action.payload;
        },
        addBrinxBux(state, action: PayloadAction<string | number>) {
            if (!isUndefined(state.brinxBuxAmount)) {
                state.brinxBuxAmount = BigNumber(state.brinxBuxAmount).plus(action.payload).toString();
            }
        },
    },
});

export const selectActiveAccordions = (state: RootState) => state.profile.activeAccordions;
export const selectBrinxBuxAmount = (state: RootState) => {
    return state.profile.brinxBuxAmount;
};

export const { toggleAccordion, setBrinxBux, addBrinxBux } = profileSlice.actions;
