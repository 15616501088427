import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SHOPIFY_URL,
    prepareHeaders: (headers) => {
        headers.set("X-Shopify-Storefront-Access-Token", process.env.REACT_APP_SHOPIFY_PUBLIC_API_KEY ?? "");
        headers.set("Content-Type", "application/json");
        return headers;
    },
});

export const shopifyApiSlice = createApi({
    reducerPath: "shopifyApi",
    tagTypes: ["Products", "ProductTags", "ProductVariants", "ProductOptions", "Cart"],
    baseQuery,
    endpoints: () => ({}),
});
