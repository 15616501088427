// Keep a tab awake and avoid an unexpected WebSocket connection closure
// When closing the connection, the lock is released by calling lockResolver().
// When the lock is released, the tab is allowed to sleep.
const webSocketsLockResolver = (socketsName: string): ((value: unknown) => void) | null => {
    let lockResolver: ((value: unknown) => void) | null = null;
    if (navigator?.locks?.request) {
        const promise = new Promise((res) => {
            lockResolver = res;
        });

        navigator.locks.request(socketsName + "sockets_lock", { mode: "shared" }, () => {
            return promise;
        });
    }

    return lockResolver;
};

export default webSocketsLockResolver;
