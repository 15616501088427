import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../../shared/api/apiSlice";
import { shopifyApiSlice } from "../../shared/api/shopifyApiSlice";
import { zypeExternalApiSlice } from "../../shared/api/zypeExternalApiSlice";
import { appSlice } from "./appSlice";
import { authSlice } from "../../shared/lib/authSlice";
import { redeemSlice } from "../../entities/redeem/model/redeemSlice";
import { shopSlice } from "../../entities/shop/model/shopSlice";
import { profileSlice } from "../../entities/profile/model/profileSlice";
import { unauthorizedCart } from "../../entities/cart/model/unauthorizedCartSlice";
import { metaApiSlice } from "../../shared/api/metaApiSlice";
import { shareResourceSlice } from "../../entities/shareResource/model/shareResourceSlice";

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        [shopifyApiSlice.reducerPath]: shopifyApiSlice.reducer,
        [zypeExternalApiSlice.reducerPath]: zypeExternalApiSlice.reducer,
        [metaApiSlice.reducerPath]: metaApiSlice.reducer,
        app: appSlice.reducer,
        auth: authSlice.reducer,
        redeem: redeemSlice.reducer,
        shop: shopSlice.reducer,
        profile: profileSlice.reducer,
        unauthorizedCart: unauthorizedCart.reducer,
        shareResource: shareResourceSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(apiSlice.middleware)
            .concat(shopifyApiSlice.middleware)
            .concat(zypeExternalApiSlice.middleware)
            .concat(metaApiSlice.middleware),
});
