import clsx from "clsx";
import styles from "../CriticalErrorMessage/CriticalErrorMessage.module.scss";
import "../CriticalErrorMessage/CriticalErrorMessage.scss";
import image from "../../../assets/UpdateScreen/Alpaca.svg";
import Button, { ButtonVariant } from "../../../shared/ui/buttons/Button/Button";
import GlobalError from "../GlobalError";

const InternetConnectionLost = () => {
    const reloadPage = () => window.location.reload();

    return (
        <GlobalError>
            <div className={clsx(styles.wrapper, "page-critical-error")}>
                <div className={styles.row}></div>
                <div className={styles.inner}>
                    <img className={styles.image} src={image} alt="critical message" />
                    <div className={styles.center}>
                        <p className={styles.curtainText}>No internet connection.</p>
                        <p className={clsx(styles.curtainText, styles.divider)}>Try refreshing the page later.</p>
                    </div>
                    <Button
                        className={styles.btn}
                        buttonVariant={ButtonVariant.Yellow}
                        title="REFRESH"
                        onClick={reloadPage}
                    />
                </div>
                <div className={styles.row}></div>
            </div>
        </GlobalError>
    );
};

export default InternetConnectionLost;
