import { apiSlice } from "../../shared/api/apiSlice";

export interface TokenResponse {
    accessToken: string;
    refreshToken: string;
    expiresAt: string;
}

export interface AuthorizeResponse extends TokenResponse {
    userId: string;
    email: string;
}

interface AuthorizeCodeRequest {
    authorizationCode: string;
    redirectUri: string;
    scope: string;
}

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        authCheck: builder.query<boolean | null, void>({
            //TODO: replace with healthcheck?
            query: () => "/auth/check",
            providesTags: ["Authorize"],
        }),
        logout: builder.mutation<void, void>({
            query: () => ({
                url: "/auth/logout",
                method: "POST",
            }),
        }),
        authorizeCode: builder.mutation<AuthorizeResponse, AuthorizeCodeRequest>({
            query: (params) => ({
                url: "/auth/authorize/code",
                method: "POST",
                body: params,
            }),
            invalidatesTags: ["Authorize", "BrinxBux", "ShowChannels", "CurrentUser"],
        }),
    }),
});

export const { useLazyAuthCheckQuery, useLogoutMutation, useAuthorizeCodeMutation } = authApiSlice;
