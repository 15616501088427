import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IUnauthorizedCartState {
    unauthorizedCartId: string | null;
}

let initialState: IUnauthorizedCartState = {
    unauthorizedCartId: localStorage.getItem("cartId"),
};

export const unauthorizedCart = createSlice({
    name: "unauthorizedCart",
    initialState,
    reducers: {
        saveUnauthorizedCartId(state, action: PayloadAction<string | null>) {
            state.unauthorizedCartId = action.payload;
            if (action.payload === null) {
                localStorage.removeItem("cartId");
                return;
            }

            localStorage.setItem("cartId", action.payload);
        },
    },
});

export const selectUnauthorizedCartId = (state: RootState) => state.unauthorizedCart.unauthorizedCartId;

export const { saveUnauthorizedCartId } = unauthorizedCart.actions;
