import { useCallback, useContext, useEffect } from "react";
import { authSliceReducers } from "../shared/lib/authSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useNavigate } from "react-router-dom";
import { getApps, initializeApp } from "firebase/app";
import { FirebaseMessaging, GetTokenOptions } from "@capacitor-firebase/messaging";
import firebaseTopic from "../features/notifications/firebaseTopic";
import { Capacitor } from "@capacitor/core";
import {
    useSubscribeTokenToTopicMutation,
    useUnsubscribeTokenFromTopicMutation,
} from "../features/notifications/notificationsApiSlice";
import { useAuthorizeCodeMutation, useLogoutMutation } from "../features/auth/authApiSlice";
import { firebaseConfig } from "../app/firebase";
import { NotificationContext } from "../features/UI/Notification/NotificationContextProvider";
import { scope } from "../features/auth/constants";
import IOperationError from "../shared/api/IOperationError";
import { useMergeUnauthorizedCartMutation } from "../features/shop/shopApiSlice";
import { saveUnauthorizedCartId, selectUnauthorizedCartId } from "../entities/cart/model/unauthorizedCartSlice";

const useAuth = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [authorize] = useAuthorizeCodeMutation();
    const { push } = useContext(NotificationContext);
    const cartId = useAppSelector(selectUnauthorizedCartId);

    //#region Notifications
    const [subscribeTokenToTopic] = useSubscribeTokenToTopicMutation();
    const [unsubscribeTokenFromTopic] = useUnsubscribeTokenFromTopicMutation();

    useEffect(() => {
        if (Capacitor.isNativePlatform()) {
            return;
        }

        if (getApps().length === 0) {
            initializeApp(firebaseConfig);
        }
    }, []);

    const requestPermissions = async (): Promise<void> => {
        await FirebaseMessaging.requestPermissions();
    };

    const subscribeTokenToGeneral = useCallback(
        async (attemptsCount: number): Promise<void> => {
            if (attemptsCount > 10) {
                console.warn("Failed to subscribe to the topic.");
            }

            const options: GetTokenOptions = {
                vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
            };

            if (Capacitor.getPlatform() === "web" && "serviceWorker" in navigator) {
                options.serviceWorkerRegistration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
            }

            try {
                const { token } = await FirebaseMessaging.getToken(options);
                if (Capacitor.getPlatform() === "web") {
                    await subscribeTokenToTopic({ token, topic: firebaseTopic.general });
                } else {
                    await FirebaseMessaging.subscribeToTopic({ topic: firebaseTopic.general });
                }
            } catch {
                subscribeTokenToGeneral(attemptsCount++);
            }
        },
        [subscribeTokenToTopic],
    );
    //#endregion

    const [mergeUnauthorizedCart] = useMergeUnauthorizedCartMutation();
    const internalLogin = useCallback(
        async (accessToken: string, refreshToken: string) => {
            const triedAccess = localStorage.getItem("triedAccess");
            const redirectTo =
                triedAccess === null ? "/" : triedAccess?.at(0) === "/" ? triedAccess : `/${triedAccess}`;
            dispatch(
                authSliceReducers.tokenReceived({
                    accessToken,
                    refreshToken,
                }),
            );
            localStorage.removeItem("triedAccess");

            if (cartId) {
                try {
                    await mergeUnauthorizedCart({ unauthorizedCartId: cartId }).unwrap();
                    dispatch(saveUnauthorizedCartId(null));
                } catch {
                    console.log("Failed to merge carts.");
                }
            }

            try {
                await requestPermissions();
                await subscribeTokenToGeneral(0);
            } catch {
                console.log("Notification permission not granted.");
            }

            navigate(redirectTo ?? "/");
        },
        [cartId, dispatch, mergeUnauthorizedCart, navigate, subscribeTokenToGeneral],
    );

    const login = useCallback(
        (authorizationCode: string) => {
            authorize({
                authorizationCode,
                redirectUri: (process.env.REACT_APP_WEB_FRONT_URL ?? "") + "/login",
                scope,
            })
                .unwrap()
                .then((response) => {
                    if (response.accessToken) {
                        internalLogin(response.accessToken, response.refreshToken);
                    } else {
                        console.error("Could not get token");
                        navigate("/");
                    }
                })
                .catch((error) => {
                    const errors = error.data as IOperationError[];
                    push(errors.map((e) => e.message).join(" "));
                });
        },
        [authorize, internalLogin, navigate, push],
    );

    const [logoutMutation] = useLogoutMutation();
    const logout = useCallback(() => {
        logoutMutation()
            .unwrap()
            .catch(() => console.log("Failed to logout."))
            .finally(async () => {
                dispatch(authSliceReducers.loggedOut());

                if (Capacitor.getPlatform() === "web") {
                    try {
                        const { token } = await FirebaseMessaging.getToken({
                            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                        });
                        if (token) {
                            await unsubscribeTokenFromTopic({ token, topic: firebaseTopic.general });
                        }
                    } catch {
                        console.log("Failed to get Firebase token.");
                    }
                } else {
                    await FirebaseMessaging.unsubscribeFromTopic({ topic: firebaseTopic.general });
                }
            });
    }, [dispatch, logoutMutation, unsubscribeTokenFromTopic]);

    return {
        login,
        logout,
    };
};

export default useAuth;
