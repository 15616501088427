import { apiSlice } from "../../shared/api/apiSlice";

export interface NotificationDto {
    id: string;
    createdDateUtc: string;
    title: string;
    message: string;
    link?: string;
}

export interface NotificationAdminDto extends NotificationDto {
    numberOfViews: number;
}

interface GetNotificationsResponse {
    notifications: NotificationDto[];
}

interface GetNotificationsAdminResponse {
    notifications: NotificationAdminDto[];
}

interface CreateNotificationRequest {
    title: string;
    message: string;
    link: string;
}

interface ViewNotificationRequest {
    id: string;
}

type TokenSubscriptionRequest = {
    token: string;
    topic: string;
};

type SubscribeTokenToTopicRequest = TokenSubscriptionRequest;

type UnsubscribeTokenFromTopicRequest = SubscribeTokenToTopicRequest;

export const notificationsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query<GetNotificationsResponse, void>({
            query: () => "/notifications",
            providesTags: ["Notifications"],
        }),
        getNotificationsAdmin: builder.query<GetNotificationsAdminResponse, void>({
            query: () => "/notifications/admin",
            providesTags: ["NotificationsAdmin"],
        }),
        createNotification: builder.mutation<void, CreateNotificationRequest>({
            query: (request) => {
                return {
                    url: "/notifications/create",
                    method: "POST",
                    body: request,
                };
            },
            invalidatesTags: ["Notifications", "NotificationsAdmin"],
        }),
        viewNotification: builder.mutation<void, ViewNotificationRequest>({
            query: (request) => {
                return {
                    url: `/notifications/${request.id}/view`,
                    method: "POST",
                };
            },
            invalidatesTags: ["Notifications"],
        }),
        clearNotification: builder.mutation<void, void>({
            query: (request) => {
                return {
                    url: `/notifications/clear`,
                    method: "POST",
                };
            },
            invalidatesTags: ["Notifications"],
        }),
        subscribeTokenToTopic: builder.mutation<void, SubscribeTokenToTopicRequest>({
            query: (request) => {
                return {
                    url: "/notifications/topics/subscribe",
                    method: "POST",
                    body: request,
                };
            },
        }),
        unsubscribeTokenFromTopic: builder.mutation<void, UnsubscribeTokenFromTopicRequest>({
            query: (request) => {
                return {
                    url: "/notifications/topics/unsubscribe",
                    method: "POST",
                    body: request,
                };
            },
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    useGetNotificationsAdminQuery,
    useCreateNotificationMutation,
    useViewNotificationMutation,
    useClearNotificationMutation,
    useSubscribeTokenToTopicMutation,
    useUnsubscribeTokenFromTopicMutation,
} = notificationsApiSlice;
