import clsx from "clsx";
import { MutableRefObject, ReactElement, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { CloseIcon } from "../../../assets";
import styles from "./Modal.module.scss";
import Button, { ButtonProps, ButtonVariant } from "../../../shared/ui/buttons/Button/Button";
import { useOutsideClick } from "../../../shared/lib/hooks";

interface IModal {
    isModalShown: boolean;
    onModalShownClose: () => void;
    children?: string | JSX.Element | JSX.Element[];
    options: IModalOptions;
}

export interface IModalOptions {
    className?: string;
    title?: string;
    icon?: string | JSX.Element | JSX.Element[];
    firstButtonTitle?: string;
    firstButtonOnClick?: () => void;
    firstButtonVariant?: ButtonVariant;
    secondButtonTitle?: string;
    secondButtonOnClick?: () => void;
    secondButtonVariant?: ButtonVariant;
    footerButtons?: ReactElement<ButtonProps>[];
    onOutsideClick?: () => void;
    isShowCloseButton?: boolean;
    isCloseOnOutsideClick?: boolean;
    isDisableFirstButton?: boolean;
    hasScroll?: boolean;
    fontFamily?: "primary" | "secondary";
}

//TODO: remove unnecessary first and secondButton props
const Modal = ({
    children,
    isModalShown,
    onModalShownClose,
    options: {
        className,
        title,
        firstButtonTitle,
        firstButtonOnClick,
        firstButtonVariant = ButtonVariant.Outlined,
        secondButtonTitle,
        secondButtonOnClick,
        secondButtonVariant = ButtonVariant.Yellow,
        footerButtons,
        onOutsideClick,
        isShowCloseButton = true,
        isCloseOnOutsideClick = true,
        isDisableFirstButton = false,
        hasScroll = false,
        fontFamily = "primary",
    },
}: IModal) => {
    const node = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement>;

    useEffect(() => {
        if (isModalShown) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }

        return () => document.body.classList.remove("no-scroll");
    }, [isModalShown]);

    useOutsideClick(node, () => {
        if (isModalShown) {
            if (isCloseOnOutsideClick) onModalShownClose();
            onOutsideClick?.();
        }
    });

    return (
        <>
            {isModalShown &&
                createPortal(
                    <div className={clsx(styles.overlay, styles.overlayShown)}>
                        <div
                            ref={node}
                            className={clsx(
                                styles.modal,
                                className,
                                fontFamily === "primary" ? styles.ffPrimary : styles.ffSecondary,
                            )}
                        >
                            <div className={styles.header}>
                                <p className={styles.headerTitle}>{title}</p>
                            </div>
                            <div className={clsx(styles.body, hasScroll && styles.hasScroll)}>{children}</div>
                            {(firstButtonTitle || secondButtonTitle || footerButtons) && (
                                <div className={styles.footer}>
                                    {firstButtonTitle && (
                                        <Button
                                            title={firstButtonTitle}
                                            buttonVariant={firstButtonVariant}
                                            onClick={firstButtonOnClick}
                                            disabled={isDisableFirstButton}
                                        />
                                    )}
                                    {secondButtonTitle && (
                                        <Button
                                            title={secondButtonTitle}
                                            buttonVariant={secondButtonVariant}
                                            onClick={secondButtonOnClick}
                                        />
                                    )}
                                    {footerButtons?.map((fb) => fb)}
                                </div>
                            )}
                            {isShowCloseButton && (
                                <div
                                    className={styles.closeBtnWrapper}
                                    onClick={() => {
                                        onModalShownClose();
                                        onOutsideClick?.();
                                    }}
                                >
                                    <button className={styles.closeBtn}>
                                        <CloseIcon className={styles.closeBtnIcon} />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>,
                    document.body,
                )}
        </>
    );
};

export default Modal;
