import { Capacitor } from "@capacitor/core";
import { ReactNode, useEffect } from "react";

interface GlobalErrorProps {
    children: ReactNode;
}
const GlobalError = (props: GlobalErrorProps) => {
    const { children } = props;

    useEffect(() => {
        if (Capacitor.getPlatform() === "ios") {
            const headerMetaTag = document.createElement("meta");
            headerMetaTag.name = "viewport";
            headerMetaTag.content = "initial-scale=1, viewport-fit=cover";
            document.head.appendChild(headerMetaTag);
            document.body.classList.add("native-ios");
            return () => {
                document.head.removeChild(headerMetaTag);
            };
        }
    }, []);

    return children;
};

export default GlobalError;
